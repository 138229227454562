import Link from 'next/link';
import { Button, Text, Stack } from '@pairy/pds-react';
import { useMediaQuery } from 'react-responsive';

import MainLayout from '../Layouts/Main';

import Form from './Form';
import * as Styled from './index.styles';

const Login = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <MainLayout isMobile={isMobile}>
      <Styled.Wrapper>
        <Text as="h1" variant="3xl-bold" textAlign="center">
          오늘은 고객이
          <br />
          어떤 새로운 반응을 보였을까요?
        </Text>
        <Text as="p" textAlign="center" color="zinc_400" variant="lg-bold">
          로그인 후 영업 힌트를 얻어가세요.
        </Text>
        <Stack direction="column" alignItems="center" gap={16}>
          <Form />
          <Link href="/signup">
            <a>
              <Button
                variant="skyblue_400"
                radius={4}
                textVariant="lg-semibold"
                width="304px"
                paddingY={12}
              >
                회원가입 하고 고객반응 보기
              </Button>
            </a>
          </Link>
        </Stack>
        <Link href="/find-password">
          <a>
            <Text variant="md-medium" color="skyblue_400">
              비밀번호를 잊으셨나요?
            </Text>
          </a>
        </Link>
      </Styled.Wrapper>
    </MainLayout>
  );
};

export default Login;
