import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useSetAtom } from 'jotai';
import { Text, Button, Input, HookFormErrorMessage } from '@pairy/pds-react';

import useSignIn from '@/hooks/mutations/auth/useSignIn';
import { userInfoAtom } from '@/state/user/atom';
import { ApiErrorResponse } from '@/types/api';
import { FORM_VALIDATIONS } from '@/constants/formValidations';

import * as Styled from './index.styles';

interface Inputs {
  email: string;
  password: string;
}

const Form = () => {
  const router = useRouter();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Inputs>();

  const setUserInfo = useSetAtom(userInfoAtom);

  const { mutateAsync } = useSignIn();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const response = await mutateAsync(data);
      setUserInfo(response.data.data.user);
      router.replace('/user/pdf');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data) {
        const { status } = error.response;

        let message = '';

        if (status === 500) {
          message = '서버 에러입니다. 잠시 후 다시 시도해주세요.';
        } else {
          message = (error.response.data as ApiErrorResponse).message;
        }

        setError('password', {
          message,
        });
      }
    }
  };

  return (
    <Styled.Wrapper onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label>
          <Text as="p" color="zinc_400" variant="sm-regular" marginBottom={8}>
            이메일
          </Text>
          <Input
            type="text"
            placeholder="user@email.com"
            isError={Boolean(errors.email)}
            {...register('email', {
              required: FORM_VALIDATIONS.email.required,
              pattern: {
                value: FORM_VALIDATIONS.email.pattern.value,
                message: FORM_VALIDATIONS.email.pattern.message,
              },
            })}
          />
        </label>
        <HookFormErrorMessage errors={errors} name="email" />
      </div>
      <div>
        <label>
          <Text as="p" color="zinc_400" variant="sm-regular" marginBottom={8}>
            비밀번호
          </Text>
          <Input
            type="password"
            isError={Boolean(errors.password)}
            {...register('password', {
              required: '비밀번호를 입력해주세요.',
            })}
          />
        </label>
        <HookFormErrorMessage errors={errors} name="password" />
      </div>
      <Button
        type="submit"
        textVariant="lg-semibold"
        variant="skyblue_400_fill"
        radius={4}
        paddingY={12}
      >
        로그인
      </Button>
    </Styled.Wrapper>
  );
};

export default Form;
