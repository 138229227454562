import {
  useMutation,
  type QueryKey,
  type UseMutationOptions,
  type UseMutationResult,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { api } from '@/utils/api';
import { ApiErrorResponse, ApiResponse } from '@/types/api';
import { UserInfo } from '@/types/user';

export interface SignInRequest {
  password: string;
  email: string;
}

export interface SignInResponse {
  user: UserInfo;
}

export const signIn = ({ password, email }: SignInRequest) => {
  return api.post<ApiResponse<SignInResponse>>('/auth/signin', {
    password,
    email,
  });
};

const useSignIn = <TError = AxiosError<ApiErrorResponse>>(
  options?: UseMutationOptions<
    AxiosResponse<ApiResponse<SignInResponse>>,
    TError,
    SignInRequest,
    QueryKey
  >,
): UseMutationResult<
  AxiosResponse<ApiResponse<SignInResponse>>,
  TError,
  SignInRequest,
  QueryKey
> => {
  return useMutation(signIn, {
    ...options,
  });
};

export default useSignIn;
